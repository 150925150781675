import api from '@/services/api';
import {
  setLoadingStatus, setCollection, addItem, setItem, setPage, addCollection, setDocument,
} from '../mutations';
import {
  items, item, getDocument,
} from '../getters';

const actions = {
  async index({ commit, rootState, state }, params = {}) {
    commit('setLoadingStatus', true);
    if (params.type !== 'document_official') {
      if (params.page) {
        commit('setPage', state.page + 1);
      }
      params.page = state.page;
    }
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v2/documents', { params });
      if (params.type === 'document_official') {
        commit('setCollectionDocumentOfficial', data.data);
      } else {
        if (state.page === 1) {
          commit('setCollection', data.data);
        }
        commit('addCollection', data.data);
      }
    } catch (error) {
      throw new Error('Não foi possível carregar a lista de documentos.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async indexByStatus({ commit, rootState }, status) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      const { data } = await api.get(`v1/documents/status/${status.toLowerCase()}`, { params });
      commit('setCollectionDocumentsWaiting', data);
    } catch (error) {
      throw new Error('Não foi possível carregar o documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async show({ commit, rootState }, id) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      const { data } = await api.get(`v1/documents/${id}`, { params });
      commit('setItem', data);
    } catch (error) {
      throw new Error('Não foi possível carregar o documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async create(
    { commit, rootState },
    {
      body,
      bookId,
      name,
      position,
      type,
      official,
      sign,
      saBookId,
      from,
      to,
      especieClasse,
      quantity,
      modality,
      date,
      number,
      director,
      otherDirectors,
      stockId,
      debentureId,
      dateLimit,
      subscriber,
      quantitySubs,
      quantityInt,
      information,
      act,
      form,
      secretary,
      title,
      address,
      hideQuotas,
      hasPriorAct,
    },
  ) {
    commit('setLoadingStatus', true);
    const companyId = rootState.company.selectedCompany.id;

    try {
      const { data } = await api.post('v1/documents', body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          company_id: companyId,
          book_id: bookId,
          name,
          position,
          type,
          official,
          sign,
          sa_book_id: saBookId,
          from,
          to,
          especie_classe: especieClasse,
          quantity,
          modality,
          date,
          number,
          director,
          other_directors: otherDirectors,
          stock_id: stockId,
          debenture_id: debentureId,
          date_limit: dateLimit,
          subscriber,
          quantity_subs: quantitySubs,
          quantity_int: quantityInt,
          information,
          act,
          form,
          secretary,
          title,
          address,
          hide_quotas: hideQuotas,
          hasPriorAct,
        },
      });
      if (type !== 'empty' && type !== 'document_official') {
        commit('addItem', data);
      }
      return data;
    } catch (error) {
      if (error.response && error.response.status === 403) throw new Error(error.response.data);
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível criar o documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async edit({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.patch(`v1/documents/${body.id}`, body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'ValidationException') throw error;
      throw new Error('Não foi possível editar o documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async cancel({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.patch(`v1/documents/cancel/${body.id}`, body);
      commit('addItem', data);
    } catch (error) {
      if (error.response && error.response.status === 403) throw new Error(error.response.data);
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível cancelar o documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async remove({ commit, rootState }, id) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      await api.delete(`v1/documents/${id}`, { params });
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível deletar.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async download({ commit, rootState }, { id }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      const { data, headers } = await api.get(`v1/documents/${id}/download`, {
        params,
        responseType: 'blob',
      });
      return { data, filename: headers.filename };
    } catch (error) {
      throw new Error('Não foi possível fazer o download do documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async showNextSAStockTransferNumber({ commit, rootState }, id) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      const { data } = await api.get(`v1/documents/next-stock-transfer-number/${id}`, { params });
      return data;
    } catch (error) {
      throw new Error('Não foi possível carregar o número do próximo documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async showNextSABeneficiaryPartyTransferNumber({ commit, rootState }, id) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      const { data } = await api.get(`v1/documents/next-beneficiary-party-transfer-number/${id}`, { params });
      return data;
    } catch (error) {
      throw new Error('Não foi possível carregar o número do próximo documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async showNextSADebentureTransferNumber({ commit, rootState }, bookId) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      const { data } = await api.get(`v1/documents/next-debenture-transfer-number/${bookId}`, { params });
      return data;
    } catch (error) {
      throw new Error('Não foi possível carregar o número do próximo documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async showSignersStatus({ commit, rootState }, id) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      const { data } = await api.get(`v1/documents/${id}/signers-status`, { params });
      return data;
    } catch (error) {
      throw new Error('Não foi possível carregar o status das assinaturas do documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async getPdf(
    { commit, rootState },
    {
      id,
      folder,
      filename,
    },
  ) {
    commit('setLoadingStatus', true);
    const companyId = rootState.company.selectedCompany.id;

    const params = {
      company_id: companyId,
      id,
      folder,
      filename,
    };

    try {
      const { data } = await api.get(`v1/documents/${id}/preview-doc`, {
        params,
      });

      commit('setDocument', data);

      return data;
    } catch (error) {
      throw new Error('Não foi possível carregar o documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async preview(
    { commit, rootState },
    {
      bookId,
      name,
      position,
      type,
      official,
      sign,
      saBookId,
      from,
      to,
      especieClasse,
      quantity,
      modality,
      date,
      number,
      director,
      stockId,
      debentureId,
      subscriber,
      quantitySubs,
      quantityInt,
      information,
      act,
      dateLimit,
      form,
      signers,
      secretary,
      title,
      address,
      hideQuotas,
      observation,
      hasPriorAct,
    },
  ) {
    commit('setLoadingStatus', true);
    const companyId = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post('v1/documents/preview', { signers, observation }, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          company_id: companyId,
          book_id: bookId,
          name,
          position,
          type,
          official,
          sign,
          sa_book_id: saBookId,
          from,
          to,
          especie_classe: especieClasse,
          quantity,
          modality,
          date,
          number,
          director,
          secretary,
          stock_id: stockId,
          debenture_id: debentureId,
          subscriber,
          quantity_subs: quantitySubs,
          quantity_int: quantityInt,
          date_limit: dateLimit,
          information,
          act,
          form,
          title,
          address,
          hide_quotas: hideQuotas,
          hasPriorAct,
        },
      });
      if (type !== 'empty') {
        commit('addItem', data);
      }
      return data;
    } catch (error) {
      if (error.response && error.response.status === 403) throw new Error(error.response.data);
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível criar o documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async checkIntegrity(
    { commit, rootState },
    {
      action,
      from,
      stockId,
      quantity,
      subscriber,
      quantitySubs,
      quantityInt,
    },
  ) {
    commit('setLoadingStatus', true);
    const companyId = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/documents/check-integrity', {
        params: {
          company_id: companyId,
          action,
          from,
          quantity,
          subscriber,
          quantity_subs: quantitySubs,
          quantity_int: quantityInt,
          stock_id: stockId,
        },
      });
      return data;
    } catch (error) {
      if (error.response && error.response.status === 403) throw new Error(error.response.data);
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível criar o documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  setLoadingStatus,
  setCollection,
  setCollectionDocumentOfficial(state, documents) {
    state.itemsOfficial = documents;
  },
  setCollectionDocumentsWaiting(state, documents) {
    state.itemsWaiting = documents;
  },
  addItem,
  setItem,
  setPage,
  addCollection,
  setDocument,
};

const getters = {
  getDocument,
  items,
  item,
  itemsOfficial(state) {
    return state.itemsOfficial;
  },
  itemsWaiting(state) {
    return state.itemsWaiting;
  },
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    items: [],
    itemsWaiting: [],
    itemsOfficial: [],
    item: {},
    page: 1,
    documentBase64: '',
  },
  getters,
  mutations,
  actions,
};
