import api from '@/services/api';
import { convertDateToTimestamp } from '@/plugins/dayjs';
import {
  setLoadingStatus, setCollection, addItem, setItem,
} from '../mutations';
import { item, items } from '../getters';

const actions = {
  async index({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/books/sa/beneficiary-party-registry', { params });
      commit('setCollection', data);
    } catch (error) {
      throw new Error('Não foi possível carregar a lista de registros de partes beneficiárias.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async create({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;

    if (body.date) {
      body.date = convertDateToTimestamp(body.date);
    }
    try {
      const { data } = await api.post('v1/books/sa/beneficiary-party-registry', body);
      commit('addItem', data);
      return data;
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível salvar os dados do registro de partes beneficiárias.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async edit({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;

    if (body.date) {
      body.date = convertDateToTimestamp(body.date);
    }
    try {
      const { data } = await api.patch(`v1/books/sa/beneficiary-party-registry/${body.id}`, body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível editar os dados do registro de partes beneficiárias.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async remove({ commit, rootState }, { id }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      await api.delete(`v1/books/sa/beneficiary-party-registry/${id}`, { params });
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível deletar o registro de partes beneficiárias.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  setLoadingStatus,
  setCollection,
  addItem,
  setItem,
};

const getters = {
  items,
  item,
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    items: [],
    item: {},
  },
  getters,
  mutations,
  actions,
};
