import api from '@/services/api';
import { todayUTC, convertDateToTimestamp, diff } from '@/plugins/dayjs';
import {
  setLoadingStatus, setCollection, addItem, setCount,
} from '../mutations';
import { items } from '../getters';

const actions = {
  async index({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/signers', { params });
      commit('setCollection', data);
    } catch (error) {
      throw new Error('Não foi possível carregar a lista.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },

  async getList({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      await api.get('v1/signers/get-list', { params });
    } catch (error) {
      throw new Error('Não foi possível carregar a lista.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },

  async count({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/signers/count', { params });
      commit('setCount', data);
      return data;
    } catch (error) {
      throw new Error('Não foi possível contar.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async create({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post('v1/signers', body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'ValidationException') throw error;
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível salvar os dados.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async edit({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.patch(`v1/signers/${body.id}`, body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'ValidationException') throw error;
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível editar os dados.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async remove({ commit, dispatch, rootState }, id) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      await api.delete(`v1/signers/${id}`, { params });
      await dispatch('index');
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível deletar.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async sendToSigners({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      await api.post('v1/signers/sign-document', body);
    } catch (error) {
      if (error.name === 'ValidationException') throw error;
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível enviar o documento para assinatura.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async resendToSigners({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post('v1/signers/resign-document', body);
      return data;
    } catch (error) {
      if (error.name === 'ValidationException') throw error;
      throw new Error('Não foi possível enviar o documento para assinatura.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async editSigner({ commit }, body) {
    commit('setLoadingStatus', true);
    try {
      const { data } = await api.patch(`v1/signers/${body.id}`, body);
      return data;
    } catch (error) {
      if (error.name === 'ValidationException') throw error;
      throw new Error('Não foi possível enviar editar os assinante');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async changeContact({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post('v1/signers/change-contact', body);
      return data;
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      if (error.name === 'ValidationException') throw error;
      throw new Error('Não foi possível enviar editar os assinante');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async signLink({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post('v1/signers/sign-link', body);
      return data;
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      if (error.name === 'ValidationException') throw error;
      throw new Error('Não foi possível consultar o link de assinatura.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async upload({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    const { id } = rootState.company.selectedCompany;
    try {
      const { data } = await api.post('v1/signers/upload-document', body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          company_id: id,
        },
      });
      return data;
    } catch (error) {
      if (error.name === 'BadRequestException') throw error;
      throw new Error('Não foi possível realizar upload dos assinantes.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  organizeSignersWithQuorum({ state, rootState, commit }) {
    const totalSubscribedPerStock = [];
    // pega o total de cada ação + o total de cada ação do acionista
    const stocks = []; // armazena apenas os stocks que tem dados
    for (let i = 0; i < state.items.length; i += 1) {
      const signer = state.items[i];
      signer.subscriptionTotal = {
        total: [], // total deste assinante
        quorum: {}, // porcentagem desse assinante em relação ao total
      };
      const bookSAStockRegistries = signer.bookSAStockRegistry
        .filter((bsr) => bsr.action !== 'Conversão')
        .filter((bsr) => convertDateToTimestamp(bsr.date) <= convertDateToTimestamp(state.from));
      if (bookSAStockRegistries.length) {
        for (let j = 0; j < bookSAStockRegistries.length; j += 1) {
          const bookSAStockRegistry = bookSAStockRegistries[j];
          if (bookSAStockRegistry.stock.voting_rights) {
            stocks[bookSAStockRegistry.stock.type] = bookSAStockRegistry.stock;
            if (!totalSubscribedPerStock[bookSAStockRegistry.stock.type]) {
              totalSubscribedPerStock[bookSAStockRegistry.stock.type] = 0;
            }
            totalSubscribedPerStock[bookSAStockRegistry.stock.type] +=
              bookSAStockRegistry.subsc_subscritas +
              bookSAStockRegistry.trans_adquiridas +
              bookSAStockRegistry.trans_transferidas +
              bookSAStockRegistry.conver_convertidas +
              bookSAStockRegistry.amort_quantidade;
            if (!signer.subscriptionTotal.total[bookSAStockRegistry.stock.type]) {
              signer.subscriptionTotal.total[bookSAStockRegistry.stock.type] = 0;
            }
            signer.subscriptionTotal.total[bookSAStockRegistry.stock.type] +=
              bookSAStockRegistry.subsc_subscritas +
              bookSAStockRegistry.trans_adquiridas +
              bookSAStockRegistry.trans_transferidas +
              bookSAStockRegistry.conver_convertidas +
              bookSAStockRegistry.amort_quantidade;
          }
        }
      }
    }
    // popula quem não tem ações com 0
    for (let i = 0; i < state.items.length; i += 1) {
      const signer = state.items[i];
      const bookSAStockRegistries = signer.bookSAStockRegistry
        .filter((bsr) => bsr.action !== 'Conversão')
        .filter((bsr) => convertDateToTimestamp(bsr.date) <= convertDateToTimestamp(state.from));
      if (!bookSAStockRegistries.length) {
        signer.subscriptionTotal = {
          total: [], // total deste assinante
          quorum: {}, // porcentagem desse assinante em relação ao total
        };
        for (let l = 0; l < Object.keys(stocks).length; l += 1) {
          const stock = Object.keys(stocks)[l];
          signer.subscriptionTotal.total[stock] = 0;
        }
      }
    }
    const originalStocks = rootState.stock.itemsCaptable.reduce((pV, cV) => {
      if (diff(convertDateToTimestamp(state.from), cV.issue_date) >= 0) {
        if (!pV[cV.type]) {
          pV[cV.type] = 0;
        }
        pV[cV.type] += cV.total;
      }
      return pV;
    }, []);
    // calcula a porcentagem de cada um perante o total de ações
    for (let i = 0; i < state.items.length; i += 1) {
      const signer = state.items[i];
      Object.keys(signer.subscriptionTotal.total).map((type) => {
        signer
          .subscriptionTotal.quorum[type] = ((signer.subscriptionTotal.total[type] * 100) /
          originalStocks[type]).toFixed(2);
        return signer;
      });
    }
    commit('setSignersWithQuorum', state.items);
    commit('setSubscribedPerStock', Object.keys(totalSubscribedPerStock));
  },
  async downloadIncomeReport({ commit, rootState }, { id, dividendDistribution, shareCapital }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
      dividend_distribution: dividendDistribution,
      share_capital: shareCapital,
    };
    try {
      const { data, headers } = await api.get(`v1/signers/${id}/download-income-report`, {
        params,
        responseType: 'blob',
      });
      return { data, filename: headers.filename };
    } catch (error) {
      if (error.name === 'ValidationException') throw error;
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível fazer o download do documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  setLoadingStatus,
  setCollection,
  addItem,
  setCount,
  setSignersWithQuorum(state, signersWithQuorum) {
    state.signersWithQuorum = [...signersWithQuorum];
  },
  setSubscribedPerStock(state, total) {
    state.subscribedPerStock = total;
  },
  setFrom(state, from) {
    state.from = from;
  },
};

const getters = {
  items,
  signersWithQuorum: (state) => state.signersWithQuorum,
  subscribedPerStock: (state) => state.subscribedPerStock,
  from: (state) => state.from,
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    items: [],
    signersWithQuorum: [],
    subscribedPerStock: [],
    count: 0,
    from: todayUTC(),
  },
  getters,
  mutations,
  actions,
};
