import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '../views/AuthExternal.vue'),
    children: [
      {
        path: '/',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: { keepToasts: true },
      },
      {
        path: '/login/reset',
        name: 'reset',
        component: () => import(/* webpackChunkName: "login" */ '../views/Reset.vue'),
        meta: { keepToasts: true },
      },
      {
        path: '/login/recover/:token',
        name: 'recover',
        props: true,
        component: () => import(/* webpackChunkName: "login" */ '../views/Recover.vue'),
        meta: { keepToasts: true },
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "default" */ '../views/InternalDefault.vue'),
    meta: { auth: true },
    redirect: { name: 'dashboard' },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        meta: {
          breacrumbs: () => [
            {
              text: 'Dashboard',
              to: '/dashboard',
            },
          ],
        },
        component: () => import(/* webpackChunkName: "default" */ '../views/Dashboard.vue'),
      },
      {
        path: 'profile',
        name: 'profile',
        meta: {
          breacrumbs: () => [
            {
              text: 'Perfil',
              to: '/profile',
            },
          ],
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
      },
      {
        path: 'companies',
        name: 'companies',
        meta: {
          breacrumbs: () => [
            {
              text: 'Empresas',
              to: '/companies',
            },
          ],
        },
        beforeEnter(to, from, next) {
          if (store.getters && !store.getters['auth/check']('administrator')) {
            Vue.toasted.error('Tela disponível somente para administradores.');
            next({ name: 'dashboard' });
          } else {
            next();
          }
        },
        component: () => import(/* webpackChunkName: "companies" */ '../views/Companies.vue'),
      },
      {
        path: 'users',
        name: 'users',
        meta: {
          breacrumbs: () => [
            {
              text: 'Usuários',
              to: '/users',
            },
          ],
        },
        beforeEnter(to, from, next) {
          if (store.getters && !store.getters['auth/check']('administrator')) {
            Vue.toasted.error('Tela disponível somente para administradores.');
            next({ name: 'dashboard' });
          } else {
            next();
          }
        },
        component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
      },
      {
        path: '',
        name: 'router-guard',
        component: () => import(/* webpackChunkName: "default" */ '../views/RouterGuard.vue'),
        redirect: { name: 'dashboard' },
        beforeEnter(to, from, next) {
          if (!store.state.company.selectedCompany) {
            Vue.toasted.error('Você primeiro precisa escolher uma empresa.');
            next({ name: 'dashboard' });
          } else {
            next();
          }
        },
        children: [
          {
            path: 'signers',
            name: 'signers',
            meta: {
              breacrumbs: () => [
                {
                  text: 'Assinantes',
                  to: '/signers',
                },
              ],
            },
            beforeEnter(to, from, next) {
              if (store.getters && (store.getters['auth/check']('administrator') || store.getters['auth/check']('editor'))) {
                next();
              } else {
                Vue.toasted.error('Tela disponível somente para administradores.');
                next({ name: 'dashboard' });
              }
            },
            component: () => import(/* webpackChunkName: "signers" */ '../views/Signers.vue'),
          },
          {
            path: 'stocks',
            name: 'stocks',
            beforeEnter(to, from, next) {
              if (store.getters && !store.getters['company/isSA']) {
                Vue.toasted.error('Tela disponível somente para SAs.');
                next({ name: 'dashboard' });
              } else {
                next();
              }
            },
            meta: {
              breacrumbs: () => [
                {
                  text: 'Ações',
                  to: '/stocks',
                },
              ],
            },
            component: () => import(/* webpackChunkName: "stocks" */ '../views/Stocks.vue'),
          },
          {
            path: 'quotas',
            name: 'quotas',
            beforeEnter(to, from, next) {
              if (store.getters && !store.getters['company/isLTDA']) {
                Vue.toasted.error('Tela disponível somente para LTDAs.');
                next({ name: 'dashboard' });
              } else {
                next();
              }
            },
            meta: {
              breacrumbs: () => [
                {
                  text: 'Quotas',
                  to: '/quotas',
                },
              ],
            },
            component: () => import(/* webpackChunkName: "quotas" */ '../views/Stocks.vue'),
          },
          {
            path: 'debentures',
            name: 'debentures',
            beforeEnter(to, from, next) {
              if (store.getters && !store.getters['company/isSA']) {
                Vue.toasted.error('Tela disponível somente para SAs.');
                next({ name: 'dashboard' });
              } else {
                next();
              }
            },
            meta: {
              breacrumbs: () => [
                {
                  text: 'Debêntures',
                  to: '/debentures',
                },
              ],
            },
            component: () => import(/* webpackChunkName: "debentures" */ '../views/Debentures.vue'),
          },
          {
            path: 'shareholding-structure',
            name: 'shareholding-structure',
            beforeEnter(to, from, next) {
              if (store.getters && (!store.getters['company/isSA'])) {
                Vue.toasted.error('Tela disponível somente para SAs.');
                next({ name: 'dashboard' });
              } else {
                next();
              }
            },
            meta: {
              breacrumbs: () => [
                {
                  text: 'Quadro acionário',
                  to: '/shareholding-structure',
                },
              ],
            },
            component: () => import(/* webpackChunkName: "cap-table" */ '../views/CapTable.vue'),
          },
          {
            path: 'shareholding-structure-ltda',
            name: 'shareholding-structure-ltda',
            beforeEnter(to, from, next) {
              if (store.getters && (!store.getters['company/isLTDA'])) {
                Vue.toasted.error('Tela disponível somente para LTDAs.');
                next({ name: 'dashboard' });
              } else {
                next();
              }
            },
            meta: {
              breacrumbs: () => [
                {
                  text: 'Quadro societário',
                  to: '/shareholding-structure-ltda',
                },
              ],
            },
            component: () => import(/* webpackChunkName: "cap-table" */ '../views/ShareholdingStructureLtda.vue'),
          },
          {
            path: 'books',
            name: 'books',
            meta: {
              breacrumbs: () => [
                {
                  text: 'Livros',
                  to: '/books',
                },
              ],
            },
            component: () => import(/* webpackChunkName: "books" */ '../views/Books.vue'),
          },
          {
            path: 'books/:id',
            props: (route) => ({ id: route.params.id, q: route.query.q }),
            name: 'books-view',
            meta: {
              breacrumbs: (route) => [
                {
                  text: 'Livros',
                  to: '/books',
                  exact: true,
                },
                {
                  text: 'Livro',
                  to: `/books/${route.params.id}`,
                },
              ],
            },
            component: () => import(/* webpackChunkName: "books" */ '../views/Book.vue'),
          },
          {
            path: 'books/sa-registro-de-acoes/:id',
            props: (route) => ({ id: route.params.id }),
            name: 'books-sa-stock-registry-view',
            meta: {
              breacrumbs: (route) => [
                {
                  text: 'Livros',
                  to: '/books',
                  exact: true,
                },
                {
                  text: 'Livro',
                  to: `/books/${route.params.id}`,
                },
              ],
            },
            component: () =>
              import(/* webpackChunkName: "books" */ '../views/BookSAStockRegistry.vue'),
          },
          {
            path: 'books/sa-beneficiary-party/:id',
            props: (route) => ({ id: route.params.id }),
            name: 'books-sa-stock-beneficiary-party-view',
            meta: {
              breacrumbs: (route) => [
                {
                  text: 'Livros',
                  to: '/books',
                  exact: true,
                },
                {
                  text: 'Livro',
                  to: `/books/${route.params.id}`,
                },
              ],
            },
            component: () =>
              import(/* webpackChunkName: "books" */ '../views/BookSABeneficiaryPartyRegistry.vue'),
          },
          {
            path: 'books/sa-registro-de-debentures/:id',
            props: (route) => ({ id: route.params.id }),
            name: 'books-sa-debenture-registry-view',
            meta: {
              breacrumbs: (route) => [
                {
                  text: 'Livros',
                  to: '/books',
                  exact: true,
                },
                {
                  text: 'Livro',
                  to: `/books/${route.params.id}`,
                },
              ],
            },
            component: () =>
              import(/* webpackChunkName: "books" */ '../views/BookSADebentureRegistry.vue'),
          },
          {
            path: 'books/:id/document',
            name: 'document-add',
            meta: {
              breacrumbs: (route) => [
                {
                  text: 'Livros',
                  href: '/books',
                  exact: true,
                },
                {
                  text: 'Livro',
                  to: `/books/${route.params.id}/`,
                  exact: true,
                },
                {
                  text: 'Adicionar documento',
                  to: `/books/${route.params.id}/document`,
                },
              ],
            },
            props: (route) => ({ id: route.params.id }),
            component: () => import(/* webpackChunkName: "books" */ '../views/DocumentAdd.vue'),
          },
          {
            path: 'books/:id/register',
            name: 'book-registration',
            meta: {
              breacrumbs: (route) => [
                {
                  text: 'Livros',
                  href: '/books',
                  exact: true,
                },
                {
                  text: 'Livro',
                  to: `/books/${route.params.id}/`,
                  exact: true,
                },
                {
                  text: 'Autenticar na junta comercial',
                  to: `/books/${route.params.id}/register`,
                },
              ],
            },
            props: (route) => ({ id: route.params.id }),
            component: () => import(/* webpackChunkName: "books" */ '../views/BookRegisterOnBoardOfTrade.vue'),
          },
          {
            path: 'library',
            name: 'library',
            meta: {
              breacrumbs: () => [
                {
                  text: 'Gestão de documentos',
                  to: '/library',
                },
              ],
            },
            component: () => import(/* webpackChunkName: "library" */ '../views/Folders.vue'),
          },
          {
            path: 'library/:id',
            props: (route) => ({ id: route.params.id }),
            name: 'library-view',
            meta: {
              breacrumbs: (route) => [
                {
                  text: 'Gestão de documentos',
                  to: '/library',
                  exact: true,
                },
                {
                  text: 'Pasta',
                  to: `/library/${route.params.id}`,
                },
              ],
            },
            component: () => import(/* webpackChunkName: "books" */ '../views/Folder.vue'),
          },
          {
            path: 'library/:id/document',
            name: 'library-add',
            meta: {
              breacrumbs: (route) => [
                {
                  text: 'Gestão de documentos',
                  to: '/library',
                  exact: true,
                },
                {
                  text: 'Pasta',
                  to: `/library/${route.params.id}/`,
                  exact: true,
                },
                {
                  text: 'Adicionar documento',
                  to: `/library/${route.params.id}/document`,
                },
              ],
            },
            props: (route) => ({ id: route.params.id }),
            component: () => import(/* webpackChunkName: "library" */ '../views/FolderAddDocument.vue'),
          },
          {
            path: 'book-models',
            name: 'book-models',
            meta: {
              breacrumbs: () => [
                {
                  text: 'Modelos',
                  to: '/book-models',
                },
              ],
            },
            component: () => import(/* webpackChunkName: "models" */ '../views/BookModels.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import(/* webpackChunkName: "default" */ '../views/Forbidden.vue'),
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "default" */ '../views/NotFound.vue'),
  },
];

Vue.router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default Vue.router;
